import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import "./legal-document.css";

export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <Helmet>
        <title>{post.frontmatter.title}</title>
      </Helmet>
      <div id="contentTextMD" dangerouslySetInnerHTML={{ __html: post.html }}/>
    </Layout>
  )
}

export const pageQuery = graphql`
    query($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            excerpt(pruneLength: 160)
            frontmatter {
                date(formatString: "DD MMMM, YYYY")
                path
                title
            }
            fields {
                readingTime {
                    text
                }
            }
        }
    }
`
